import Middleware from "../middleware";

Middleware.search = function ({ app }) {
  if (!app.$search.getIsuid()) {
    app.$search.setIsuid();
  }

  if (!app.$search.getuserId()) {
    app.$search.setUserId();
  }

  if (!app.$search.getSessionIdNamespace()) {
    app.$search.setSessionIdNamespace();
  }
};
