export const state = () => ({
  productCount: -1,
});

export const actions = {
  setProductCount({ commit }, { count }) {
    commit("SET_PRODUCT_COUNT", { count });
  },
};

export const mutations = {
  SET_PRODUCT_COUNT(state, { count }) {
    state.productCount = count;
  },
};
