import { v4 as uuidv4 } from "uuid";

const CATEGORY = "searchspring.recommendations.user-interactions";
const PROFILE_IMPRESSION_TYPE = "profile.impression";
const PRODUCT_IMPRESSION_TYPE = "profile.product.impression";
const PROFILE_RENDER_TYPE = "profile.render";
const PRODUCT_RENDER_TYPE = "profile.product.render";
const PROFILE_CLICK_TYPE = "profile.click";
const PRODUCT_CLICK_TYPE = "profile.product.click";

export const state = () => ({
  placement: "other",
  seed: [],
  products: null,
  visibleProductIds: null,
});

export const getters = {
  getProducts(state) {
    return tag => state.products?.[tag] ?? [];
  },
  getVisibleProductIds(state) {
    return tag => state.visibleProductIds?.[tag] ?? [];
  },
  getEventProduct(state, getters) {
    return (productId, tag) => ({
      id: productId,
      mappings: getters
        .getProducts(tag)
        .find(product => product.id == productId)?.mappings,
      ...(state.seed.length > 0 && { seed: state.seed }),
    });
  },
};

export const actions = {
  init({ commit }, { placement, seed }) {
    commit("init", { placement, seed });
  },
  async fetchProducts({ commit, getters }, { tag, limit, sku } = {}) {
    const products = getters.getProducts(tag);
    if (products.length != 0) {
      return;
    }

    const { data } = await this.$search.recommend({ tag, limit, sku });

    commit("setProducts", { tag, products: data.results });
  },
  setVisibleProductsIds({ commit }, { tag, productId }) {
    commit("setVisibleProductsIds", { tag, productId });
  },
  async trackBeacon(
    { state, commit, getters, dispatch },
    { type, productId, tag }
  ) {
    const profileContext = this.$search.getProfileContext();

    const eventContext = {
      tag,
      placement: state.placement,
      type: "product-recommendation",
    };

    const eventProfile = {
      tag,
      placement: state.placement,
      ...(state.seed.length > 0 && { seed: state.seed }),
    };

    const getProfile = ({ type, id }) => {
      return {
        type,
        id,
        category: CATEGORY,
        context: profileContext,
        event: {
          context: eventContext,
          profile: eventProfile,
        },
      };
    };

    const getProductProfile = ({ type, pid, productId }) => {
      return {
        type,
        id: uuidv4(),
        category: CATEGORY,
        context: profileContext,
        event: {
          context: eventContext,
          product: getters.getEventProduct(productId, tag),
        },
        pid,
      };
    };

    const id = uuidv4();

    if (type === "render") {
      await this.$search.callBeaconApi([
        getProfile({ type: PROFILE_RENDER_TYPE, id }),
        ...getters.getProducts(tag).map(product =>
          getProductProfile({
            type: PRODUCT_RENDER_TYPE,
            pid: id,
            productId: product.id,
          })
        ),
      ]);
    }

    if (type === "impression") {
      await this.$search.callBeaconApi([
        getProfile({ type: PROFILE_IMPRESSION_TYPE, id }),
        ...getters.getVisibleProductIds(tag).map(productId =>
          getProductProfile({
            type: PRODUCT_IMPRESSION_TYPE,
            pid: id,
            productId,
          })
        ),
      ]);
      commit("clearVisibleProductIds", { tag });
    }

    if (type === "click") {
      await dispatch(
        "callBeaconApi",
        [
          getProfile({ type: PROFILE_CLICK_TYPE, id }),
          productId &&
            getProductProfile({
              type: PRODUCT_CLICK_TYPE,
              pid: id,
              productId,
            }),
        ].filter(Boolean)
      );
    }

    if (type === "clickImpression") {
      await this.$search.callBeaconApi([
        getProfile({ type: PROFILE_CLICK_TYPE, id }),
        ...getters.getVisibleProductIds(tag).map(productId =>
          getProductProfile({
            type: PRODUCT_IMPRESSION_TYPE,
            pid: id,
            productId,
          })
        ),
      ]);
      commit("clearVisibleProductIds", { tag });
    }
  },
};

export const mutations = {
  init(state, { placement, seed }) {
    state.placement = placement;
    state.seed = seed;
    state.products = null;
    state.visibleProductIds = null;
  },
  setProducts(state, { tag, products }) {
    state.products = {
      ...state.products,
      [tag]: products,
    };
  },
  setVisibleProductsIds(state, { tag, productId }) {
    const productIds = [...(state.visibleProductIds?.[tag] ?? []), productId];

    state.visibleProductIds = {
      ...state.visibleProductIds,
      [tag]: productIds,
    };
  },
  clearVisibleProductIds(state, { tag }) {
    state.visibleProductIds = {
      ...state.visibleProductIds,
      [tag]: [],
    };
  },
};
