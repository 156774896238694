export const state = () => ({
  blogPage: null,
  blogs: null,
  blogsNavigation: [],
});

export const getters = {
  blogPageMeta(state) {
    if (!state.blogPage) {
      return null;
    }

    const { uid, type } = state.blogPage;

    const {
      title,
      meta_title: metaTitle,
      meta_description: metaDescription,
      meta_robots: metaRobots,
    } = state.blogPage.results[0].data;

    return { title, metaTitle, metaDescription, metaRobots, uid, type };
  },
  getBlog(state) {
    return uid => state.blogs?.[uid];
  },
};

export const actions = {
  async fetchBlogPage({ commit, state }) {
    if (state.blogPage) {
      return;
    }

    try {
      const response = await this.$prismicApi.predicateQuery(
        this.$prismicApi.Predicates.at("document.type", "blogpage")
      );

      if (response.results.length == 0) {
        console.error("No blog page in Prismic");
        return;
      }

      commit("SET_BLOG_PAGE", { blogPage: response });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },

  async fetchBlog({ state, commit }, { handle }) {
    if (state.blogs?.[handle]) {
      return;
    }

    const blogData = await this.$prismicApi.predicateQuery(
      this.$prismicApi.Predicates.at("my.blog.uid", handle)
    );

    if (blogData.results.length > 0) {
      commit("UPSERT_BLOGS", { blog: blogData.results[0] });
    }
  },
};

export const mutations = {
  SET_BLOG_PAGE(state, { blogPage }) {
    state.blogPage = blogPage;
  },
  UPSERT_BLOGS(state, { blog }) {
    state.blogs = { ...state.blogs, [blog.uid]: blog };
  },
};
