/**
 *
 * @param {string} str
 * @param {string} charToReplace
 * @returns {string}
 */
export default function slugify(str, charToReplace = "-") {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, charToReplace);
}
