import { camelize } from "~/utils";

export default function (doc) {
  const { _meta: meta, fromCanonical = false, page } = doc;

  let uid, type;

  if (meta && meta.uid) {
    ({ uid, type } = meta);
  }

  if (doc.uid) {
    ({ uid, type } = doc);
  }

  if (type === "become_a_member") {
    return `/${uid}`;
  }

  if (type === "page") {
    return `/pages/${uid}`;
  }

  if (type === "store-locator" || type === "store_page") {
    return `/store/${uid}`;
  }

  if (type === "static_page") {
    return `/static-pages/${uid}`;
  }

  if (type === "blog") {
    return `/blogs/inspiration/${uid}`;
  }

  if (type === "contact_us") {
    return `/contact-us`;
  }

  if (type === "product_search_page") {
    return `/products/search`;
  }

  if (type === "blogpage") {
    const url = page ? `/blogs/${uid}?page=${page}` : `/blogs/${uid}`;
    return url;
  }

  if (type === "taggedblog") {
    return `/blogs/${uid}/tagged/${meta.tag}`;
  }

  if (type === "collection_page") {
    return `/collections/${uid}`;
  }

  if (type === "product_list_page") {
    // https://jira.nagarro.com/browse/SNOOZE-3297
    if (fromCanonical && (uid == "mattresses" || uid == "all-mattresses")) {
      return "/collections/mattresses";
    }

    return `/collections/${uid}/products`;
  }

  if (type === "product_detail_page") {
    return `/products/${uid}`;
  }

  if (type === "l2_product_list_page") {
    let [collectionUid = "", facetKey = "", facetValue = ""] = uid.split("__");
    facetValue = camelize(facetValue, true, " ");

    return `/collections/${collectionUid}/${facetKey}/${facetValue}`;
  }

  if (type === "campaign_landing_page1") {
    return `/campaign/${uid}`;
  }

  if (type === "cart") {
    return `/cart`;
  }

  if (type === "error_page") {
    return `/error/404`;
  }

  if (type === "work_with_us") {
    return `/work-with-us`;
  }

  return "/";
}
