import {
  pathOr,
  flatten,
  equals,
  match,
  map,
  intersection,
  compose,
  split,
  find,
  length,
} from "ramda";

import { colors } from "~/colors.config";
import { getPrismicTime } from "~/utils/prismic-utils.js";

const maximum = xs => {
  const max = Math.max(...xs);
  return Number.isFinite(max) ? max : 0;
};

const getMaxDiscount = productTags =>
  compose(
    maximum,
    flatten,
    map(match(/\d+/g)),
    intersection(productTags),
    split(","),
    pathOr("", ["tags"])
  );

const getPriorityCampaign = productTags =>
  find(
    compose(length, intersection(productTags), split(","), pathOr("", ["tags"]))
  );

export default {
  data: () => ({
    currentCampaigns: [],
    intervalHandle: null,
    maxDiscount: 0,
    priorityCampaign: null,
  }),

  created() {
    // if (process.client) {
    // First set the current campaigns
    // await this.$store.dispatch("GET_CAMPAIGN_SETTINGS");
    this.campaignSetting = this.$store.state.campaignSetting?.campaign_setting;
    this.setCurrentCampaigns();
    // }
  },

  computed: {
    campaignFor() {
      return pathOr("", ["saleTag"], this.priorityCampaign);
    },
  },

  methods: {
    /**
     *
     * @param {*} product will come from searchspring/shopify
     */
    getSaleTag({ tags, campaign, price, compareAtPrice }) {
      this.priorityCampaign = getPriorityCampaign(tags)(this.currentCampaigns);

      // To find and set the priority campaign that can be used.
      // For campaign tag
      if (this.priorityCampaign) {
        const maxDiscount = getMaxDiscount(tags)(this.priorityCampaign);
        this.maxDiscount =
          maxDiscount == 0
            ? this.priorityCampaign?.salePercentage ?? 0
            : maxDiscount;

        return;
      }

      // For Campaign
      if (campaign.saleColor && campaign.saleTitle) {
        this.priorityCampaign = {
          saleColor: campaign.saleColor,
          saleTitle: campaign.saleTitle,
          saleTileTitle: campaign.saleTitle,
          saleTileTitleMobile: campaign.saleTileTitleMobile,
          pdpSaleTitle: "",
          pdpSaleBody: "",
          plpSaleBody: "",
          campaignTitle: "",
          enhancedUI: false,
          showRRPText: campaign.saleTitle !== "Clearance",
        };
        return;
      }

      // For normal Sale
      const comparing =
        Number(compareAtPrice) && Number(compareAtPrice) > Number(price);

      if (comparing) {
        this.priorityCampaign = {
          saleColor: colors.campaign.sale,
          saleTitle: "SALE",
          saleTileTitle: "SALE",
          saleTileTitleMobile: "SALE",
          pdpSaleTitle: "SALE",
          pdpSaleBody: "",
          plpSaleBody: "",
          enhancedUI: false,
          showRRPText: true,
        };
      }
    },

    getCurrentCampaigns() {
      return this.campaignSetting.filter(
        ({ startTime, endTime, enableCampaign }) =>
          Date.now() >= getPrismicTime(startTime) &&
          Date.now() <= getPrismicTime(endTime) &&
          enableCampaign
      );
    },
    setCurrentCampaigns() {
      this.currentCampaigns = this.getCurrentCampaigns();

      const checkEquality = () => {
        const campaigns = this.getCurrentCampaigns();
        if (!equals(this.currentCampaigns, campaigns)) {
          this.currentCampaigns = campaigns;
        }
      };

      // will stop the campaign if time elapsed by setting this.currentCampaigns = undefined
      // As getCampaign will return undefined in that case
      if (process.client) {
        this.intervalHandle = setInterval(checkEquality, 1000);
      } else {
        // to prevent memory leak do not call setInterval on client
        checkEquality();
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalHandle);
  },
};
