import { gql } from "graphql-tag";
import { externalLink, documentLink } from "~/app/prismic/prismic-fragments";

export const campaignSettingQuery = gql`
  query campaignSetting($uid: String!, $appUid: String!, $lang: String!) {
    campaign_settings: campaign_settings1(uid: $uid, lang: $lang) {
      campaign_setting {
        saleTitle: sale_title
        enableCampaign: enable_campaign
        startTime: start_time
        endTime: end_time
        saleTileTitle: sale_tile_title
        saleTileTitleMobile: sale_tile_title_mobile
        pdpSaleTitle: pdp_sale_title
        pdpSaleBody: pdp_sale_body
        plpSaleBody: plp_sale_body
        saleColor: sale_color
        salePercentage: sale_percentage
        tags: tags
        campaignTitle: campaign_title
        enhancedUI: enhanced_ui
        showRRPText: show_rrp_text
        pdpSalePrice: pdp_sale_price
      }
    }
    app_settings(uid: $appUid, lang: $lang) {
      badge_group {
        badgeTitle: badge_title
        badgeTag: badge_tag
        badgeColor: badge_color
      }
      tag_base_add_to_cart {
        tag_name
        button_text
        button_link {
          ...externalLink
          ...documentLink
        }
      }
      l2_plp_facet_mapping {
        facet_key
        facet_value
      }
    }
  }
  ${externalLink}
  ${documentLink}
`;
