export const state = () => ({
  recommendedProduct: null,
});

export const actions = {
  setRecommendedProduct({ commit }, product) {
    commit("setRecommendedProduct", product);
  },
};

export const mutations = {
  setRecommendedProduct(state, product) {
    state.recommendedProduct = product;
  },
};
