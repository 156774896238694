export default {
  methods: {
    /**
     *
     * @param {array} array
     * @param {array} order
     * @param {string} arrayKey
     * @param {string} orderKey
     * @returns {array} result
     */
    multiSort(array, order, arrayKey, orderKey) {
      const orderMap = new Map();
      order.forEach((facet, index) => {
        orderMap.set(facet[orderKey], index);
      });

      return array.slice().sort((a, b) => {
        const aIndex = orderMap.get(a[arrayKey]);
        const bIndex = orderMap.get(b[arrayKey]);

        if (aIndex > bIndex) {
          return 1;
        } else {
          return -1;
        }
      });
    },
  },
};
