import module from "./products-store.js";

const CLEAR_REFINEMENT = "CLEAR_REFINEMENT";
const REFINEMENT_LIST = "REFINEMENT_LIST";
const CURRENT_REFINEMENTS = "CURRENT_REFINEMENTS";
const INFINITE_HITS = "INFINITE_HITS";
const WIDGETS = [
  CLEAR_REFINEMENT,
  REFINEMENT_LIST,
  CURRENT_REFINEMENTS,
  INFINITE_HITS,
];

class CustomURLSearchParams extends URLSearchParams {
  /**
   * Since has with value is only supported with node 20,
   * so we have to add it here
   *
   * @param {string} name
   * @param {string} [value]
   * @returns {boolean}
   */
  has(name, value) {
    return value ? super.getAll(name).includes(value) : super.has(name);
  }
}

/**
 *
 * @param {Record<string, string> |  string[][] | string | URLSearchParams} [init]
 * @returns {URLSearchParams}
 */
function getURLSearchParams(init) {
  return new CustomURLSearchParams(init);
}

function searchResultTransformer(data) {
  data = JSON.parse(data);

  const results = (data?.results ?? []).map(result => ({
    ...result,
    id: result.uid,
    title: result.title.replace(/&amp;/g, "&"),
    options: JSON.parse(result.options.replace(/&quot;/g, '"')),
    variants: JSON.parse(result.variants.replace(/&quot;/g, '"')),
    color_swatches: JSON.parse(result.color_swatches.replace(/&quot;/g, '"')),
    metafields: JSON.parse(result.metafields.replace(/&quot;/g, '"')),
  }));

  return { ...data, results };
}

export default ({ app }, inject) => {
  app.store.registerModule("productsSearch", module);
  const siteId = "g28s7j";

  /**
   * @param {Object} options
   * @param {string} options.queryPath
   * @param {number} options.pageSize
   * @param {string} options.collection
   * @param {Record<string, string>} options.facetKeyValues
   */
  function start({ queryPath, pageSize, collection, facetKeyValues = [] }) {
    const searchParams = getURLSearchParams(queryPath);
    let bgfacets = collection ? [["bgfilter.collections", collection]] : [];

    facetKeyValues.forEach(({ key, value }) => {
      let filterKey = `filter.${key}`;
      if (!searchParams.has(filterKey, value)) {
        searchParams.append(filterKey, value);
      }
    });

    app.store.dispatch("productsSearch/initState", {
      pageSize,
      bgfacets,
      queryParams: [...searchParams.entries()],
    });

    return _search();
  }
  /**
   *
   * @param {CLEAR_REFINEMENT|REFINEMENT_LIST|CURRENT_REFINEMENTS|INFINITE_HITS} widget
   * @param {Object} facetOrFilter
   * @param {string} facetOrFilter.field
   * @param {string|{rangeHigh: string, rangeLow: str}} facetOrFilter.value
   * @param {string} facetOrFilter.multiple
   */
  async function refine(widget, { field, value: fieldValue, multiple } = {}) {
    let value = fieldValue;
    if (value?.rangeHigh && value?.rangeLow) {
      value = [fieldValue.rangeLow, fieldValue.rangeHigh];
    }

    const facetParams = _getQueryParams(widget, { field, multiple, value });
    app.store.dispatch("productsSearch/setFacetParams", { facetParams });
    app.store.dispatch("productsSearch/setPage", { page: 1 });

    const response = await _search();

    app.store.dispatch("productsSearch/updateURLQuery");

    return response;
  }

  async function showMore(page) {
    const response = await _search({ page });

    app.store.dispatch("productsSearch/setPage", { page });
    app.store.dispatch("productsSearch/updateURLQuery");

    return response;
  }

  function showPrevious(page) {
    return _search({ page });
  }

  async function sort({ field, direction }) {
    app.store.dispatch("productsSearch/setPage", { page: 1 });
    app.store.dispatch("productsSearch/setSortingParams", { field, direction });

    const response = await _search();
    app.store.dispatch("productsSearch/updateURLQuery");

    return response;
  }

  /**
   * @param {CLEAR_REFINEMENT|REFINEMENT_LIST|CURRENT_REFINEMENTS|INFINITE_HITS} widget
   * @param {Object} facetOrFilter
   * @param {string} facetOrFilter.field
   * @param {string|{rangeHigh: string, rangeLow: str}} facetOrFilter.value
   * @param {string} facetOrFilter.multiple
   */
  function createURL(widget, { field, value: fieldValue, multiple } = {}) {
    let value = fieldValue;
    if (fieldValue?.rangeHigh && fieldValue?.rangeLow) {
      value = [fieldValue.rangeLow, fieldValue.rangeHigh];
    }

    const queryParams = _getQueryParams(widget, { field, value, multiple });
    const searchState = app.store.state.productsSearch;

    return getURLSearchParams([
      ["query", searchState.query],
      ...searchState.sortingParams,
      ...searchState.otherParams,
      ...queryParams,
    ]).toString();
  }

  /**
   *
   * @param {CLEAR_REFINEMENT|REFINEMENT_LIST|CURRENT_REFINEMENTS} widget
   * @param {Object} option
   * @param {string} option.field
   * @param {string|string[]} option.value
   * @param {"single"|"multiple"} option.multiple
   */
  function _getQueryParams(
    widget,
    { field, value, multiple = "multiple" } = {}
  ) {
    console.assert(WIDGETS.includes(widget), "widget should be one of WIDGETS");

    const filterKey = `filter.${field}`;
    const searchState = app.store.state.productsSearch;

    if (widget === "CLEAR_REFINEMENT") {
      return [];
    }

    if (widget === "CURRENT_REFINEMENTS") {
      const facetParams = [...searchState.facetParams].filter(
        ([key, facetValue]) =>
          !(key === filterKey && value === facetValue) &&
          !(
            Array.isArray(value) &&
            value.length === 2 &&
            (key === `${filterKey}.low` || key === `${filterKey}.high`)
          )
      );

      return facetParams;
    }

    if (widget === "REFINEMENT_LIST") {
      let searchParams = getURLSearchParams(searchState.facetParams);

      if (Array.isArray(value) && value.length == 2) {
        const lowKey = `${filterKey}.low`;
        const highKey = `${filterKey}.high`;
        if (
          searchParams.has(lowKey, value[0]) &&
          searchParams.has(highKey, value[1])
        ) {
          searchParams.delete(lowKey);
          searchParams.delete(highKey);
        } else {
          searchParams.set(lowKey, value[0]);
          searchParams.set(highKey, value[1]);
        }

        return [...searchParams.entries()];
      }

      if (searchParams.has(filterKey, value)) {
        searchParams.delete(filterKey, value);
      } else {
        multiple === "single"
          ? searchParams.set(filterKey, value)
          : searchParams.append(filterKey, value);
      }

      return [...searchParams.entries()];
    }
  }

  // async function _getSuggestions(q) {
  //   console.assert(q, "q is required");

  //   const params = getURLSearchParams({ siteId, q, suggestionCount: 2 });
  //   const suggestUrl = `https://${siteId}.a.searchspring.io/api/suggest/query?${params}`;
  //   const { data } = await app.$axios(suggestUrl);
  //   return data;
  // }

  /**
   *
   * @param {Object} options
   * @param {string|number} options.page
   */
  async function _search({ page } = {}) {
    app.store.dispatch("productsSearch/setLoading", true);
    const searchState = app.store.state.productsSearch;
    let q = searchState.query;

    let searchParams = getURLSearchParams([
      ["page", page ?? searchState.page],
      ["resultsPerPage", searchState.pageSize],
      ["siteId", siteId],
      ["resultsFormat", "json"],
      ...searchState.bgfacetParams,
      ...searchState.facetParams,
      ...searchState.sortingParams,
      ...Object.entries({
        ...app.$search.getUserTrackingParams(),
        ...app.$search.getPersonalizationParams(),
      }),
    ]);

    if (q) {
      // console.log({ q });
      // const suggestions = await _getSuggestions(q);
      // q = suggestions.suggested?.text ?? suggestions.alternatives[0]?.text ?? q;
      searchParams.set("q", q);
    }

    // call search api
    searchParams.set("page", searchParams.get("page") ?? 1);
    const url = `https://${siteId}.a.searchspring.io/api/search/search.json?${searchParams}`;

    try {
      const response = await app.$axios.get(url, {
        transformResponse: [searchResultTransformer],
      });

      return response;
    } catch (err) {
      app.$bugsnag.notify(err);
      throw err;
    } finally {
      await new Promise(resolve => {
        setTimeout(resolve, 500);
      });

      app.store.dispatch("productsSearch/setLoading", false);
    }
  }

  inject("productsSearch", {
    sort,
    start,
    refine,
    createURL,
    showMore,
    showPrevious,
  });
};
