export default () => ({
  deliveryTitles: {
    standard: "Standard Delivery",
    express: "Express Delivery",
  },
  // shop metafields
  shop: {},

  assemblyVariants: [],

  // Shipping rates
  shippingRates: [],
  hasAssembly: false,
  hasFilteredQuickship: false,
  selectedShippingRate: null,

  // delivery estimate
  deliveryEstimating: false,
  updating: false,
  estimatorErrors: [],

  // Sharing cart
  showShareEmailSentMessage: false,

  // Offer Data
  offerTags: [],
  showOffer: false,

  // online donation item
  showDonation: false,

  // cart
  cart: null,
  miniCart: {
    id: null,
    lineItemsCount: 0,
    totalPrice: 0,
  },
  cartError: false,
});
