import { mapCookieConsent } from "./cookiebot-utils";

let cookiebotConsentListenerAdded = false;

function addScript({ src, attributes }) {
  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement("script");
    scriptElement.src = src;
    scriptElement.type = "text/javascript";

    attributes.forEach(({ name, value }) => {
      scriptElement.setAttribute(name, value);
    });

    scriptElement.onload = () => {
      console.log("Script loaded:", src);
      resolve();
    };

    scriptElement.onerror = () => {
      console.error("Script failed to load:", src);
      reject(new Error(`Script failed to load: ${src}`));
    };

    document.head.appendChild(scriptElement);
  });
}

function onCookiebotConsentReady() {
  if (!window.Cookiebot) {
    return console.warn("Cookiebot not available");
  }

  if (cookiebotConsentListenerAdded) {
    return console.log("Consent event listener already added");
  }

  window.addEventListener("CookiebotOnConsentReady", () => {
    const { consent } = window.Cookiebot;

    window.Shopify?.customerPrivacy?.setTrackingConsent(
      {
        analytics: consent.statistics,
        marketing: consent.marketing,
        preferences: consent.preferences,
        sale_of_data: consent.marketing, // Mapping sale_of_data to marketing
        headlessStorefront: true,
        checkoutRootDomain: "checkout.snooze.com.au",
        storefrontRootDomain: "www.snooze.com.au",
        storefrontAccessToken: "374948ed8143715d901bb66f8ffc0ba7",
      },
      () => console.log("Consent captured")
    );
    cookiebotConsentListenerAdded = true;
  });
}

export default (options, inject) => {
  const scriptsToLoad = [
    {
      src: "https://consent.cookiebot.com/uc.js",
      attributes: [
        { name: "hid", value: "cookiebot-api" },
        { name: "data-cbid", value: "f7ea49a5-fd72-470c-9945-087e2aaaad00" },
        { name: "data-blockingmode", value: "auto" },
      ],
      defer: true,
    },
    {
      src: "https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js",
      attributes: [{ name: "hid", value: "cookie-consent-api" }],
      defer: true,
    },
  ];

  Promise.all(scriptsToLoad.map(addScript))
    .then(onCookiebotConsentReady)
    .catch(err => console.error("Error loading scripts:", err));

  inject("cookieConsent", {
    showBanner() {
      if (window.Cookiebot) {
        window.Cookiebot.show();
        console.info("Cookiebot consent banner explicitly opened");
      } else {
        console.error("Cookiebot is not loaded");
      }
    },

    isConsented(category) {
      if (!category) {
        return false;
      }
      const { consent } = window.Cookiebot || {};
      return category.split(",").every(cat => consent?.[mapCookieConsent(cat)]);
    },

    afterConcentEvent(category, callback) {
      const executeCallback = () => setTimeout(callback, 1000); // Defer to ensure GTM is loaded

      // Define named handler functions so they can be removed later
      const acceptHandler = () => {
        if (this.isConsented(category)) executeCallback();
      };

      // Clear any existing handlers
      window.removeEventListener("CookiebotOnAccept", this._acceptHandler);

      // Store new handlers for future removal
      this._acceptHandler = acceptHandler;

      // Add new handlers
      if (!this.isConsented(category)) {
        window.addEventListener("CookiebotOnAccept", acceptHandler);
      } else if (window.Cookiebot.consented) {
        executeCallback();
      }
    },
  });
};
