import { path } from "ramda";

export default {
  methods: {
    makeTitle(slug) {
      let words = slug.split("-");

      words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

      return words.join(" ");
    },
    gtmProductClickEvent(hit) {
      this.$gtm.push({
        event: "EECproductClick",
        ecommerce: {
          currencyCode: "AUD",
          click: {
            actionField: {
              list: hit.list,
            }, // Or recommendation list
            products: [
              {
                name: hit.title,
                id: hit.sku, // please use the Prod-Nr.
                price: hit.price.toString(),
                category: hit.list,
                brand: hit.vendor,
                variant: hit.variant_title, // variant of the product (optional)
                list: hit.list, // Name of the product list
                position: hit.position ? hit.position : "N/A", // Position of that specific product within the list
                dimension126: "", // productSize (Custom Dimension on product scope)
                dimension127: hit.color_hex, // productColor
                dimension128: String(hit.inventory_quantity), // productStock
                dimension129: this.getIsPriceReduced(
                  hit.compareAtPrice,
                  hit.price
                ), // productIsPriceReduced
                dimension130: "N/A", // productRating
                dimension137: "N/A", // productEAN
                dimension139: "N/A", // productInPromotion
                dimension140: "N/A", // productCollection
                dimension142: "N/A", // productDeliveryTime
                dimension143: "true", // productOrderable
                dimension153: hit.product_type ? hit.product_type : "Product", // productType
                dimension154: "N/A", // productDeliveryType
              },
            ],
          },
        },
      });
    },
    getCollections(arg) {
      let collections = JSON.parse(JSON.stringify(arg));
      let category = "";

      for (let i = 0; i < collections.length; i++) {
        category += (category == "" ? "" : "/") + collections[i];
      }

      return category;
    },
    getDiscount(compareAtPrice, price) {
      return compareAtPrice - price;
    },
    getIsPriceReduced(compareAtPrice, price) {
      let isPriceReduced = false;
      if (compareAtPrice > price) {
        isPriceReduced = true;
      }
      return isPriceReduced;
    },
    getSize(dataSet) {
      let height = path(["height", "value"], dataSet);
      let width = path(["width", "value"], dataSet);
      let depth = path(["depth", "value"], dataSet);
      let size = height + "cm x " + width + "cm x " + depth;
      return size;
    },
    gtmProductDetailView(dataSet) {
      this.$gtm.push({
        event: "EECproductDetailView",
        ecommerce: {
          currencyCode: "AUD",
          detail: {
            products: [
              {
                name: dataSet.name,
                id: dataSet.sku,
                price: dataSet.price.toString(),
                shopifyProductId: dataSet.product.id,
                category: this.getCollections(dataSet.collections),
                brand: dataSet.brand,
                variant: dataSet.title, // variant of the product (optional)
                productimageURL: path(["image", "src"], dataSet),
                dimension126: this.getSize(dataSet), // productSize (Custom Dimension on product scope)
                dimension127: dataSet.color_hex, // productColor
                dimension128: dataSet.available ? "In Stock" : "Out of Stock", // productStock
                dimension129: this.getIsPriceReduced(
                  dataSet.compareAtPrice,
                  dataSet.price
                ), // productIsPriceReduced
                dimension130: "N/A", // productRating
                dimension135: "", // productCouponName
                dimension137: "N/A", // productEAN
                dimension139: "N/A", // productInPromotion
                dimension140: dataSet.collections.length
                  ? dataSet.collections[0]
                  : "", // productCollection
                dimension142: dataSet?.lead_time_days_min + " Days", // productDeliveryTime
                dimension143: "true", // productOrderable
                dimension153: dataSet.type, // productType
                dimension154: "N/A", // productDeliveryType
                dimension158: "", // productCheckoutOption
                dimension159: "", // productShippingDistributor
                dimension160: "", // productStoreId
                metric103: "", // productCouponValue
                metric104: this.getDiscount(
                  dataSet.compareAtPrice,
                  dataSet.price
                ), // productDiscountValue
              },
            ],
          },
        },
      });
    },
    gtmCheckoutEvent(items) {
      let products = items.map(item => {
        let variant = item.variant;
        let isPriceReduced =
          Number(variant.compareAtPrice) > Number(variant.price)
            ? "true"
            : "false";
        let discountValue =
          Number(variant.compareAtPrice) - Number(variant.price);
        return {
          name: item.title,
          id: variant.sku, // please use the Prod-No
          price: variant.price.toString(),
          category: "N/A",
          brand: variant?.product?.vendor,
          variant: variant.title, // variant of the product (optional)
          quantity: item.quantity, // Number of products
          dimension126: "", // productSize (Custom Dimension on product scope)
          dimension127: "", // productColor
          dimension128: "", // productStock
          dimension129: isPriceReduced, // productIsPriceReduced
          dimension130: "N/A", // productRating
          dimension135: "", // productCouponName
          dimension137: "N/A", // productEAN
          dimension139: "N/A", // productInPromotion
          dimension140: "N/A", // productCollection
          dimension142: "N/A", // productDeliveryTime
          dimension143: "true", // productOrderable
          dimension153: variant?.product?.productType, // productType
          dimension154: "N/A", // productDeliveryType
          dimension158: "", // productCheckoutOption
          dimension159: "", // productShippingDistributor
          dimension160: "", // productStoreId
          metric103: "", // productCouponValue
          metric104: discountValue.toString(), // productDiscountValue
        };
      });

      this.$gtm.push({
        event: "EECcheckout",
        ecommerce: {
          currencyCode: "AUD",
          checkout: {
            actionField: {
              step: "1",
              option: undefined,
            },
            products,
          },
        },
      });
    },
    gtmAddToCart(item) {
      this.$gtm.push({
        event: "EECaddToCart",
        ecommerce: {
          currencyCode: "AUD",
          add: {
            products: [
              {
                name: item.name,
                id: item.sku, // please use the Prod-Nr.
                price: item.price.toString(),
                category: this.getCollections(item.collections),
                brand: item.brand,
                variant: item.title, // variant of the product (optional)
                quantity: "1", // Number of added products
                dimension126: this.getSize(item), // productSize (Custom Dimension on product scope)
                dimension127: item.color_hex, // productColor
                dimension128: item.available ? "In Stock" : "Out of Stock", // productStock
                dimension129: this.getIsPriceReduced(
                  item.compareAtPrice,
                  item.price
                ), // productIsPriceReduced
                dimension130: "N/A", // productRating
                dimension135: "", // productCouponName
                dimension137: "N/A", // productEAN
                dimension139: "N/A", // productInPromotion
                dimension140: item.collections.length
                  ? item.collections[0].title
                  : "", // productCollection
                dimension142: item?.lead_time_days_min + " Days", // productDeliveryTime
                dimension143: "true", // productOrderable
                dimension153: item.type, // productType
                dimension154: "N/A", // productDeliveryType
                dimension158: "", // productCheckoutOption
                dimension159: "", // productShippingDistributor
                dimension160: "", // productStoreId
                metric103: "", // productCouponValue
                metric104: this.getDiscount(item.compareAtPrice, item.price), // productDiscountValue
              },
            ],
          },
        },
      });
    },
  },
};
