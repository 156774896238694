import { equals } from "ramda";

const INFINITE_HITS_SESSION_NAME = "ss.infiniteHits";

const cache = {
  write({ cachedState, hits, $bugsnag }) {
    try {
      globalThis.sessionStorage?.setItem(
        INFINITE_HITS_SESSION_NAME,
        JSON.stringify({ cachedState, hits })
      );
    } catch (err) {
      console.error(err);
      $bugsnag.notify(err);
    }
  },
  read({ cachedState }) {
    try {
      const cachedData = globalThis.sessionStorage?.getItem(
        INFINITE_HITS_SESSION_NAME
      );
      const data = cachedData ? JSON.parse(cachedData) : {};
      return equals(cachedState, data.cachedState) ? data.hits : [];
    } catch (err) {
      return [];
    }
  },
};

// based on cache
// function getFirstRecievedPage(cachedState, page = 1) {
//   const cachedHits = cache.read({ cachedState }) ?? {};
//   const pages = Object.keys(cachedHits).map(Number);

//   return pages.length === 0 ? page : Math.min(page, ...pages) + 1;
// }

function getFirstRecievedPage(infiniteHits, page = 1) {
  const pages = Object.keys(infiniteHits ?? {}).map(Number);

  return pages.length === 0 ? page : Math.min(page, ...pages) + 1;
}

export default {
  namespaced: true,

  state: () => ({
    started: false,
    loading: false,
    currentPageResults: null,
    infiniteHits: null,
    query: "",
    pageSize: 12,
    page: 1,
    sortingParams: [],
    facetParams: [],
    bgfacetParams: [],
    otherParams: [],
    initalPageResult: null,
  }),

  getters: {
    cachedState(state) {
      return { query: state.query, facetParams: state.facetParams };
    },
    hits(state) {
      return Object.values(state.infiniteHits ?? {}).flat() ?? [];
    },
    isFirstPage(state, getters) {
      return (
        state.page === 1 ||
        state.page === undefined ||
        state.currentPageResults.pagination.previousPage == 0 ||
        getFirstRecievedPage(state.infiniteHits, state.page) === 1
      );
    },
    isLastPage(state) {
      return state.currentPageResults?.pagination.totalPages <= state.page;
    },
    getFacetValues: state => facetField => {
      // TODO: Implement feature to load more facet values
      const facets = state.currentPageResults?.facets ?? [];
      const facet = facets.find(({ field }) => field === facetField);
      return facet?.values.slice(0, 10) ?? [];
    },
  },

  actions: {
    initState(
      { commit, dispatch },
      { pageSize = 12, queryParams, bgfacets } = {}
    ) {
      commit("SET_STARTED", true);

      commit(
        "SET_FACET_PARAMS",
        queryParams.filter(([key]) => key.startsWith("filter."))
      );

      commit(
        "SET_OTHER_PARAMS",
        queryParams.filter(
          ([key]) => !/(^query$|^filter\.|^page$|^sort\.)/.test(key)
        )
      );

      const query = queryParams.find(([key]) => key === "query")?.[1] ?? "";
      commit("SET_QUERY", { query });

      const page = queryParams.find(([key]) => key === "page")?.[1] ?? 1;
      commit("SET_PAGE", { page });

      commit("SET_PAGE_SIZE", { pageSize });

      commit("SET_BGFACET_PARAMS", bgfacets);

      const sorting =
        queryParams.find(([key]) => key.startsWith("sort.")) ?? [];

      dispatch(
        "setSortingParams",
        sorting?.length > 0
          ? {
              field: sorting[0].replace("sort.", ""),
              direction: sorting[1],
            }
          : {}
      );
    },
    hydrate({ commit, getters }) {
      commit(
        "SET_INFINITE_HITS",
        cache.read({ cachedState: getters.cachedState })
      );
    },
    async start(
      { commit, getters },
      { pageSize, queryPath, collection, facetKeyValues }
    ) {
      const payload = { queryPath, pageSize, collection, facetKeyValues };
      const { data } = await this.$productsSearch.start(payload);
      commit("SET_INITIAL_PAGE_RESULT", { data });
      commit("SET_SEARCH_RESULTS", { data, cachedState: getters.cachedState });
    },
    async refine({ commit, getters, state }, { widget, facet }) {
      if (!state.started) {
        throw new Error("First call start method of this store module.");
      }

      const { data } = await this.$productsSearch.refine(widget, facet);

      if (widget === "CLEAR_REFINEMENT") {
        commit("SET_INITIAL_PAGE_RESULT", { data });
      }

      commit("SET_SEARCH_RESULTS", { data, cachedState: getters.cachedState });
    },
    async sort({ commit, getters, state }, sortingOption) {
      if (!state.started) {
        throw new Error("First call start method of this store module.");
      }

      const { data } = await this.$productsSearch.sort(sortingOption);
      commit("SET_SEARCH_RESULTS", { data, cachedState: getters.cachedState });
    },
    async showMore({ state, commit, getters }) {
      if (!state.started) {
        throw new Error("First call start method of this store module.");
      }

      const page = Number(state.page) + 1;
      const { data } = await this.$productsSearch.showMore(page);

      const payload = { data, cachedState: getters.cachedState };
      commit("UPDATE_SEARCH_RESULTS", payload);
    },
    async showPrevious({ commit, getters, state }) {
      if (!state.started) {
        throw new Error("First call start method of this store module.");
      }

      const page = getFirstRecievedPage(state.infiniteHits, state.page) - 1;
      const { data } = await this.$productsSearch.showPrevious(page);

      const payload = { data, cachedState: getters.cachedState };
      commit("UPDATE_SEARCH_RESULTS", payload);
    },
    updateURLQuery({ state }) {
      const searchParams = new URLSearchParams(state.facetParams);
      const queryParams = [...searchParams.keys()].map(key => {
        const values = searchParams.getAll(key);
        return [key, values.length > 1 ? values : values[0]];
      });

      this.$router.push({
        query: {
          ...(state.query && { query: state.query }),
          page: state.page,
          ...Object.fromEntries(state.sortingParams),
          ...Object.fromEntries(state.otherParams),
          ...Object.fromEntries(queryParams),
        },
      });
    },
    setFacetParams({ commit }, { facetParams }) {
      commit("SET_FACET_PARAMS", facetParams);
    },
    setPage({ commit }, { page }) {
      commit("SET_PAGE", { page });
    },
    setSortingParams({ commit }, { field, direction }) {
      const sortingParams =
        field && direction ? [[`sort.${field}`, direction]] : [];
      commit("SET_SORTING_PARAMS", sortingParams);
    },
    dispose({ commit }) {
      commit("SET_STARTED", false);
    },
    setLoading({ commit }, flag) {
      commit("SET_LOADING", flag);
    },
  },

  mutations: {
    SET_SEARCH_RESULTS(state, { data, cachedState }) {
      if (data == null) {
        state.currentPageResults = null;
        state.infiniteHits = null;
      } else {
        const page = data.pagination?.currentPage ?? 1;
        state.infiniteHits = { [page - 1]: data.results };
        state.currentPageResults = data;
      }

      cache.write({
        cachedState,
        hits: state.infiniteHits,
        $bugsnag: this.$bugsnag,
      });
    },
    UPDATE_SEARCH_RESULTS(state, { data, cachedState }) {
      const page = data.pagination?.currentPage ?? 1;
      state.infiniteHits = { ...state.infiniteHits, [page - 1]: data.results };
      state.currentPageResults = data;

      cache.write({
        cachedState,
        hits: state.infiniteHits,
        $bugsnag: this.$bugsnag,
      });
    },
    SET_INITIAL_PAGE_RESULT(state, { data }) {
      state.initalPageResult = data;
    },
    SET_INFINITE_HITS(state, hits = []) {
      state.infiniteHits = { ...hits, ...state.infiniteHits };
    },
    SET_PAGE(state, { page }) {
      state.page = page;
    },
    SET_PAGE_SIZE(state, { pageSize }) {
      state.pageSize = pageSize;
    },
    SET_QUERY(state, { query }) {
      state.query = query;
    },
    SET_FACET_PARAMS(state, params) {
      state.facetParams = params;
    },
    SET_OTHER_PARAMS(state, params) {
      state.otherParams = params;
    },
    SET_BGFACET_PARAMS(state, params) {
      state.bgfacetParams = params;
    },
    SET_STARTED(state, flag) {
      state.started = flag;
    },
    SET_SORTING_PARAMS(state, sortingParams) {
      state.sortingParams = sortingParams;
    },
    SET_LOADING(state, flag) {
      state.loading = flag;
    },
  },
};
