const CookibotFieldMapping = new Map([
  ["analytics", "statistics"],
  ["marketing", "marketing"],
  ["preferences", "preferences"],
]);

export const mapCookieConsent = category => {
  if (!category) {
    return "";
  }
  return category
    .split(",")
    .map(cat => CookibotFieldMapping.get(cat) || cat)
    .join(",");
};
