import { parseGid } from "@shopify/admin-graphql-api-utilities";

/* eslint-disable no-var */
const createWishlistItemRef = ({ wishlistId, variantId, productId }) =>
  `${wishlistId}_${productId}_${variantId}`;

export const state = () => ({
  token: null,
  customer: null,
  wishlistId: "",
  wishlistItems: [],
  currentModalType: null,
  modalOpenedFrom: null,
  addToWishlistProduct: null,
  modalTypes: {
    LOGIN_MODAL: "LOGIN_MODAL",
    WISHLIST_ADD_MODAL: "WISHLIST_ADD_MODAL",
    MEMBERSHIP_MODAL: "MEMBERSHIP_MODAL",
  },
  fillColor: "#e81728",
});

export const getters = {
  getWishlistItemRef(state) {
    return (productId, variantId) =>
      createWishlistItemRef({
        wishlistId: state.wishlistId,
        productId,
        variantId,
      });
  },
  getItem(state, getters) {
    return (productId, variantId) =>
      state.wishlistItems.find(
        item =>
          item.wishlistItemRef ===
          getters.getWishlistItemRef(productId, variantId)
      );
  },
  getItemByProductId(state) {
    return productId =>
      state.wishlistItems.find(
        ({ wishlistItemRef }) =>
          wishlistItemRef.includes(productId) &&
          wishlistItemRef.includes(state.wishlistId)
      );
  },
  wishlistItems(state, getters, rootState) {
    const tagsToExclude = rootState.appSettings.tag_base_add_to_cart.map(
      item => item.tag_name
    );

    return state.wishlistItems.map(({ product, attributeGroups, ...rest }) => {
      const attrs = attributeGroups.addToCartProps?.attributes;
      const onlyInStore = tagsToExclude.some(tag => attrs?.tags?.includes(tag));

      return { ...rest, attrs, product, onlyInStore };
    });
  },
};

export const actions = {
  async signup({ commit }, { body }) {
    const { customer } = await this.$wishlist.signup(body);
    commit("SET_CUSTOMER", customer);
  },
  async login({ commit }, { email }) {
    const { customer } = await this.$wishlist.login(email);

    if (!customer) {
      throw new Error("CUSTOMER_NOT_FOUND");
    }

    commit("SET_CUSTOMER", customer);
  },
  async postLogin({ state, dispatch }) {
    (await dispatch("fetchWishlist")) ?? (await dispatch("createWishlist"));

    const { email, customer_id: memberId } = state.customer;
    await dispatch(
      "bag/ADD_MEMBERSHIP_ATTRS",
      { email, memberId },
      { root: true }
    );
  },
  async fetchCustomer({ commit, dispatch }) {
    const { data } = await this.$axios.get("/wishlist/api/customer");
    if (!data.customer?.id) {
      console.log("Customer is not logged in");
      return;
    }

    const { customer, token } = data;
    commit("SET_CUSTOMER", customer);
    dispatch("setToken", { token });
  },
  async fetchWishlist({ commit, dispatch, state }) {
    const customerId = state.customer?.id;
    if (!customerId) {
      console.log("Customer is not logged in");
      return;
    }

    var data = await this.$wishlist.getWishlistsById(customerId);
    if (!data) {
      return;
    }

    const wishlist = data.wishlist[0];
    commit("INIT", wishlist);

    if (wishlist.lastEvaluatedItemId) {
      await dispatch("fetchWishlistItems", wishlist);
    }

    return data;
  },
  async createWishlist({ commit, state }) {
    const customerId = state.customer.id;
    if (!customerId) {
      throw new Error("CustomerId is not logged in");
    }

    const data = await this.$wishlist.createWishlistsById(customerId);
    commit("INIT", data);
  },
  async fetchWishlistItems(
    { commit },
    { lastEvaluatedItemId: lastItemId, id }
  ) {
    let wishlistItems = [];
    do {
      try {
        var data = await this.$wishlist.getWishlistItemsById(id, lastItemId);
        wishlistItems = [...wishlistItems, ...(data?.wishlistItems ?? [])];
      } catch (err) {
        return;
      }

      lastItemId = data?.lastEvaluatedItemId;
    } while (lastItemId);

    commit("ADD_WISHLIST_ITEMS", { wishlistItems });
  },
  addWishlistItems({ commit }, { wishlistItems }) {
    commit("ADD_WISHLIST_ITEMS", { wishlistItems });
  },
  async addItem(
    { commit, state, getters },
    { product, selectedVariant, listname, position = 1 }
  ) {
    const { compareAtPrice = 0, id: variantId } = selectedVariant;
    const brand = product.metafields?.brand_list ?? "";
    const category = product.metafields?.product_category ?? "";
    const tags = product.tags?.join(",") ?? "";

    const productId = parseGid(product.id);
    const itemRef = getters.getWishlistItemRef(productId, variantId);

    const item = await this.$wishlist.addItem({
      itemRef,
      productId,
      variantId,
      wishlistId: state.wishlistId,
      attributeGroups: {
        addToCartProps: {
          attributes: { compareAtPrice, brand, category, tags },
        },
      },
    });
    commit("ADD_ITEM", item);

    const { sku, title: varaintTitle, price } = selectedVariant;
    const payload = {
      currency: "AUD",
      value: price,
      items: [
        {
          item_id: sku,
          shopify_product_id: productId,
          item_name: product.title,
          discount:
            Number(compareAtPrice) > Number(price)
              ? Number(compareAtPrice) - Number(price)
              : 0,
          item_brand: brand,
          item_category: category,
          item_variant: varaintTitle,
          quantity: 1,
          ...(listname && {
            item_list_id: listname,
            item_list_name: listname,
          }),
          price,
          index: position,
        },
      ],
    };
    console.log({ payload });
    this.$gtm.gtagEvent("add_to_wishlist", payload);
  },
  async updateItem(
    { commit, state, getters },
    { productId, oldSelectedVariantId, selectedVariantId, id }
  ) {
    const itemRef = getters.getWishlistItemRef(productId, selectedVariantId);

    const updatedItem = await this.$wishlist.updateItemByRef({
      id,
      itemRef,
      productId,
      selectedVariantId,
      oldSelectedVariantId,
      wishlistId: state.wishlistId,
    });

    const oldItemRef = getters.getWishlistItemRef(
      productId,
      oldSelectedVariantId
    );
    commit("UPDATE_WISHLIST_ITEM", { itemRef: oldItemRef, updatedItem });
  },
  async removeItem({ commit, getters }, { productId, variantId }) {
    const itemRef = getters.getWishlistItemRef(productId, variantId);
    await this.$wishlist.removeItemByRef(itemRef);
    commit("REMOVE_ITEM", { itemRef });
  },
  async removeAll({ dispatch, state }) {
    await this.$wishlist.deleteWishlistById(state.wishlistId);
    await dispatch("createWishlist");
  },
  setToken({ commit }, { token }) {
    commit("SET_TOKEN", {
      ...token,
      expiryDate: new Date().getTime() + (token.expires_in - 30) * 1000,
    });
  },
  openModal({ commit }, { type, modalOpenedFrom, payload }) {
    commit("SET_CURRENT_MODAL", {
      type,
      modalOpenedFrom: modalOpenedFrom ?? "OTHER",
    });
    commit("SET_ADD_TO_WISHLIST_PRODUCT", payload);
  },
  closeModal({ commit, state }) {
    commit("SET_CURRENT_MODAL", {
      type: null,
      modalOpenedFrom: state.modalOpenedFrom,
    });
    commit("SET_ADD_TO_WISHLIST_PRODUCT", null);
  },
  setAddToWishlistProduct({ commit }, payload) {
    commit("SET_ADD_TO_WISHLIST_PRODUCT", payload);
  },
  clearAddToWishlistProduct({ commit }) {
    commit("SET_ADD_TO_WISHLIST_PRODUCT", null);
  },
};

export const mutations = {
  INIT(state, { id, wishlistItems }) {
    state.wishlistId = id;
    state.wishlistItems = wishlistItems;
  },
  ADD_WISHLIST_ITEMS(state, { wishlistItems }) {
    state.wishlistItems = [...state.wishlistItems, ...wishlistItems];
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_ADD_TO_WISHLIST_PRODUCT(state, payload) {
    state.addToWishlistProduct = payload;
  },
  SET_CURRENT_MODAL(state, { type, modalOpenedFrom }) {
    state.currentModalType = type;
    state.modalOpenedFrom = modalOpenedFrom;
  },
  ADD_ITEM(state, item) {
    state.wishlistItems = [...state.wishlistItems, item];
  },
  REMOVE_ITEM(state, { itemRef }) {
    state.wishlistItems = state.wishlistItems.filter(
      item => item.wishlistItemRef !== itemRef
    );
  },
  UPDATE_WISHLIST_ITEM(state, { itemRef, updatedItem }) {
    state.wishlistItems = state.wishlistItems.map(item =>
      itemRef == item.wishlistItemRef ? updatedItem : item
    );
  },
};
